@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
@import "./variables.scss";

body {
  background-color: $gray-100;
  color: $gray-darker;
  font-family: "Fira Sans", sans-serif;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
}

a {
  color: $legacy-purple;

  &:hover,
  &:focus {
    color: darken($legacy-purple, 15%);
  }
}
