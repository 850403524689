@import "styles/variables.scss";

.WelcomeScreen-heading {
  color: $legacy-goldenrod;
}

.btn.btn-link.WelcomeScreen-readMore {
  color: $legacy-purple;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
