@import "styles/variables.scss";

.Header {
  background-color: $legacy-blue;
  padding: 20px;
}

.Header-contentWrap {
  display: flex;
  align-items: center;
  width: 100%;

  h1 {
    @media all and (max-width: 1024px) {
      font-size: 2rem;
    }

    @media all and (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media all and (max-width: 320px) {
      font-size: 1rem;
    }
  }
}

.Header-image {
  width: 250px;
  margin-right: 25px;

  @media all and (max-width: 768px) {
    display: none;
  }
}

.Header-text {
  margin-bottom: 0;
  color: white;
}

.Header-title {
  @media all and (min-width: 768px) {
    font-size: 2.35rem;
    border-bottom: 3px solid $legacy-white;
  }
}

.Header-subtitle {
  @media all and (max-width: 768px) {
    display: none;
  }

  margin-bottom: 0;
  font-size: .88rem;
}
