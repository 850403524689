// Default HTML colors used by the Legacy Project
$legacy-white: #ffffff;
$legacy-black: #000000;

// Legacy Gold Tones
$legacy-goldenrod: #d1ad51;
$legacy-goldenrod-light: lighten($legacy-goldenrod, 20%);
$legacy-gold: #c68400;
$legacy-gold-light: #ffb300;
$legacy-gold-very-light: #ffda00;
$legacy-yellow: #fce7b0;

// Legacy Gray Tones
$legacy-gray-light: #f5f5f6;
$legacy-gray-dark: #2c2c2c;
$legacy-gray: #e1e2e1;

// Legacy Blue Tones
$legacy-blue: #000051;
$legacy-blue-light: #1a237e;
$legacy-blue-dark: #04015d;

// Legacy Accent Colors
$legacy-purple: #524aac;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$font-size-base: 18px !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
