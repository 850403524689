@import "styles/variables.scss";

.SelectedConditions {
  background-color: $gray-100;
  padding: 10px;
  margin-bottom: 10px;
}

.SelectedConditions-container {
  h5 {
    color: $legacy-purple;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 10px;
  }
}
