@import "styles/variables.scss";

.Filters {
  .card {
    border: none;
    background: inherit;
  }

  .accordion > .card > .card-header {
    margin-bottom: 0;
  }

  .card-body {
    padding: 10px 0;
    max-height: 300px;
    overflow: scroll;
  }
}

.Filters-buttons {
  margin-top: 12px;

  .btn-primary {
    background-color: $legacy-blue;
    border-color: $legacy-blue;

    &:hover,
    &:focus {
      background-color: lighten($legacy-blue, 15%);
      border-color: lighten($legacy-blue, 15%);
    }

    &:disabled {
      background-color: $legacy-gray-dark;
      border-color: $legacy-gray-dark;

      &:hover,
      &:focus {
        background-color: $legacy-gray-dark;
        border-color: $legacy-gray-dark;
      }
    }
  }

  .btn-link {
    color: $legacy-purple;

    &:hover,
    &:focus {
      color: darken($legacy-purple, 15%);
    }
  }
}

.Filters-buttonsText {
  margin-top: 10px;
  text-align: center;
}

.Filters-sidebarExternalLink {
  margin-top: 20px;
}
