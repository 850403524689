@import "styles/variables.scss";

.Home {
  margin: 0 -15px;
}

.Home-image {
  width: 100%;
}

.Home-content {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;

  @media all and (max-width: 768px) {
    padding: 20px;
  }
}

.Home-header {
  margin-top: 20px;
  color: $legacy-goldenrod;
}

.Home-button.btn-primary {
  background-color: $legacy-blue;
  border-color: $legacy-blue;
  padding: 15px 60px;

  &:hover,
  &:focus {
    background-color: lighten($legacy-blue, 15%);
    border-color: lighten($legacy-blue, 15%);
  }
}

.Home-thanks {
  margin-top: 20px;
}
