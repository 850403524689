@import "styles/variables.scss";

.SearchPage-filters {
  background-color: $gray-200;
  padding: 20px 0;
}

.SearchPage-results {
  padding: 20px 0;
}
