.FilterTitle {
  display: inline;
}

.FilterTitle-tooltip.tooltip.bs-tooltip-bottom {
  min-width: 250px;

  .tooltip-inner {
    min-width: 250px;
  }
}
