@import "styles/variables.scss";

.Footer {
  background-color: $legacy-blue;
  padding: 20px;
  padding: 40px 20px;
  color: $legacy-white;
}

.Footer-legacyProjectLink {
  color: $legacy-white;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: darken($legacy-white, 10%);
  }
}

.Footer-license {
  margin-top: 10px;
}
