@import "styles/variables.scss";

.FilterAccordionToggle {
  color: $gray-darker;
  margin: 10px 0;

  h5 {
    font-size: 1.15rem;
  }
}

.FilterAccordionToggle {
  &:hover {
    cursor: pointer;
  }

  &.card-header {
    background-color: inherit;
    border-bottom: 2px solid $gray-400;
    padding: 10px 10px 10px 0;
  }
}

.ContextAwareFilterToggle-toggle {
  float: right;
  font-size: 32px;
  line-height: 0.75;
}

.FilterAccordionToggle-letter button {
  background: inherit;
  border: 1px solid $legacy-gray;
  height: 42px;
  width: 42px;
}

.FilterAccordionToggle-letter.FilterAccordionToggle--isSelectedLetter button {
  background: $legacy-black;
  color: $legacy-white;
}

.Filters .FilterAccordionToggle-tags.card-body {
  overflow: auto;

  &.FilterAccordionToggle--isMenuOpen {
    height: 300px;
  }
}
